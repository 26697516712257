@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
.font-condensed {
    font-stretch: condensed;
}
}

.y-scrollable-tag::-webkit-scrollbar {
    width: 8px; /* Adjust scrollbar width */
}

.y-scrollable-tag::-webkit-scrollbar-thumb {
    background: #282733; /* Set scrollbar thumb color */
    border-radius: 2px; /* Apply rounded corners */
}

.y-scrollable-tag::-webkit-scrollbar-track {
    background: transparent; /* Set scrollbar track color */
}

.x-scrollable-tag::-webkit-scrollbar {
    width: 6px; /* Adjust scrollbar width */
    height: 6px;
}

.x-scrollable-tag::-webkit-scrollbar-thumb {
    background: #282733; /* Set scrollbar thumb color */
    border-radius: 2px; /* Apply rounded corners */
}

.user-profile-section {
    padding: 5px 10px;
    border-radius: 0.25rem;
    gap: 6px;
    transition: .5s all ease;
    cursor: pointer;
}

.notification-icon {
    padding: 0.25rem;
    transition: .5s all ease;
    cursor: pointer;
}

.notification-icon:hover .svg-icon path{
    fill:  #0060D9; 
}

.notification-icon:hover {
    background-color: #282733;
}

.user-profile-section:hover {
    background-color: #282733;
}

.profile-user-name {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: white;
    text-align: center;
    align-items: center;
    margin: 0px;
    padding: 0px;
}
.history-content {
    padding-top: 8px;
    padding-left: 8px;
    padding-right: 25px;
    display: flex;
}
.history-item {
    padding: 10px;
    border-bottom: 1px solid #2F2D3B;
    gap: 10px;
}
.history-item-icon {
    width: 36px;
    height: 36px;
    border-radius: 4px;
}
.history-item-text {
    font-weight: 400;
    color: white;
    font-size: 14px;
    line-height: 17.5px;
}
.navli {
    transition: .5s all ease;
}
.parent-navlink {
    transition: .5s all ease;
}
.parent-navlink:hover .nav-border {
    background-color:  #0060D9; 
}
.parent-navlink-active .nav-border {
    background-color:  #0060D9; 
}
.parent-navlink:hover .svg-icon path {
    fill:  #0060D9; 
}
.parent-navlink-active .svg-icon path {
    fill:  #0060D9; 
}
.banner-item {
    transition: .4s all ease;
}  
.banner-item:hover {
    translate: -0.02rem;
    filter: drop-shadow(0px 0px 15px rgba(6, 116, 252, 0.2));
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 9px;
}
.banner-img:hover .extend-play {
    opacity: 100;
    filter: drop-shadow(0px 0px 10px rgba(6, 116, 252, 0.65));
    transition: .2s all ease-out;
}
.banner-img:hover .banner-play{
    opacity: 0;
    transition: .2s all ease-out;
}
.fill-btn-primary {
    transition: .5s all ease;
}
.fill-btn-primary:hover {
    box-shadow: 0px 5px 10px 0px rgba(6, 116, 252, 0.45) !important;
    background-color: rgb(6, 96, 217);
}
.fill-btn-second {
    transition: .5s all ease;
}
.fill-btn-second:hover {
    box-shadow: 0px 5px 10px 0px rgba(253, 54, 59, 0.45) !important;
    background-color: rgb(253, 54, 59);
}
.outline-btn {
    transition: .5s all ease;
}
.outline-btn:hover{
    transition: .5s all ease;
    background-color: rgb(33, 32, 42, 0.85);
    color:rgb(0, 96, 217);
}
input[type="checkbox"].form-checkbox:checked + svg {
    fill: #0060D9; /* Change the color of the check mark when checked */
}
.slideshow {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 300px; /* Adjust height as needed */
}

.slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.slide.active {
    opacity: 1;
}

.group:hover .svg-icon path{
    fill: #0060D9;
}

.svg-icon:hover path{
    fill: #0060D9;
}

.menu-item:hover .svg-icon path {
    fill: #0060D9;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent black */
    z-index: 9998;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background: transparent;
    border-radius: 8px;
    max-height: 90%; 
    overflow-y: auto; /* Enables vertical scrolling if content overflows */
    position: relative; /* Required for positioning close button or other elements inside */
  }

.line-clamp-ellipsis-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* =========================
Custom Input range 
=============================*/

.progress {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
  }
  
  /* time */
  .time {
    color: #fff;
    font-size: 13px;
    line-height: 46px;
  }
  
  .time.current {
    color: #3D83F7;
  }

/* Input range - chrome and safari */

input[type="range"] {
    --range-progress: 0;
    
    -webkit-appearance: none;
    position: relative;
    background:#ccc;
    width: 100%;
    height: 3px;
    border-radius: 3px;
    cursor: pointer;
  }
  
  /* Input range - firefox */
  input[type="range"]::-moz-range-track {
    position: relative;
    background:#ccc;
    width: 100%;
    height: 3px;
    border-radius: 3px;
    cursor: pointer;
  }
  
  /* played progress length - Chrome & safari*/
  input[type="range"]::before {
    content: '';
    height: 3px;
    background: #0060D9;
    width: var(--range-progress);
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  /* played progress length - firefox */
  input[type="range"]::-moz-range-progress {
    background: #0060D9;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    height: 3px;
  }
  
  /* slider thumb - chrome and safari */
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    border: none;
    background-color: #0060D9;
    cursor: pointer;
    visibility: hidden;
    position: relative;
  }
  
  /* dragging thumb - chrome and safari */
  input[type="range"]:active::-webkit-slider-thumb {
    transform: scale(1.2);
    visibility: visible;
  }

  input[type="range"]:hover::-webkit-slider-thumb {
    transform: scale(1.2);
    visibility: visible;
  }
  
  /* slider thumb - firefox */
  input[type="range"]::-moz-range-thumb {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: #0060D9;
    cursor: pointer;
    border: transparent;
    position: relative;
  }
  /* dragging thumb - firefox */
  input[type="range"]:active::-moz-range-thumb {
    transform: scale(1.2);
  }