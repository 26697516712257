/* Generated by Glyphter (http://www.glyphter.com) on  Fri Mar 08 2024*/
@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('../fonts/PlusJakartaSans.ttf'), format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Plus Jakarta Sans Bold';
    src: url('../fonts/PlusJakartaSans-Bold.ttf'), format('truetype');
    font-weight: bold;
    font-style: normal;
}